import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout/layout"
import SEO from "components/seo"
import Row from "components/row"
import Heading from "components/heading"
import { seoDataFromPage } from "components/utils/data-formatting"

import l from "components/layout/layout.module.scss"

export default ({ pageContext, data: queryData }) => {
  const { title } = pageContext
  const { content } = queryData.wordpressPage

  const meta = seoDataFromPage(queryData.wordpressPage)

  return (
    <Layout>
      <SEO title={title} meta={meta} />
      <Row customClass={l.archive_title_section}>
        <Heading type="h1" color="white">
          {title}
        </Heading>
        <div
          className={l.privacyverklaring}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Row>
    </Layout>
  )
}

// page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      content
      ...SEOData
    }
  }
`
